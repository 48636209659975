<template>
    <div class="vl-parent">
        <loading :active="isLoading" :can-cancel="true" :is-full-page="true" />
        <Layout>
            <PageHeader :title="title" :items="items" />
            <div class="row mb-2">
                <div class="col-lg-6">
                    <div class="text-lg-right"></div>
                </div>
                <div class="col-lg-6">
                    <div class="text-lg-right">

                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <!-- <b-button id="popover-target-1" variant="light" class="mr-2">
                                <i class="mdi mdi-clock font-size-16"></i> Recent Activity
                            </b-button>
                            <b-popover target="popover-target-1" triggers="click" placement="auto">
                                <template #title>Activity Project</template>
                                <div data-simplebar style="max-height: 330px; width: 270px;">
                                    <ul class="list-unstyled activity-wid" style="  width: 400px;">
                                        <li class="activity-list" v-for="(data, index) in activityData" :key="index">
                                            <div class="activity-icon avatar-xs">
                                                <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                                                    <i :class="`${data.icon}`"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <div>
                                                    <h5 class="font-size-13">
                                                        {{ data.date }}
                                                        <small class="text-muted">{{ data.time }}</small>
                                                    </h5>
                                                </div>

                                                <div>
                                                    <p class="text-muted mb-0">{{ data.title }}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </b-popover> -->
                                <b-dropdown id="dropdown-text" class="m-2" right text="Right align">
                                    <template #button-content>
                                        <i class="mdi mdi-clock font-size-16"></i> Recent Activity
                                    </template>
                                    <b-dropdown-text style="width: 250px;max-height:400px;overflow: auto;">
                                        <template #title>Activity Project</template>
                                        <div data-simplebar>
                                            <ul class="list-unstyled activity-wid" v-if="activityData.length != 0">
                                                <li class="activity-list" v-for="(data, index) in activityData"
                                                    :key="index">
                                                    <div class="activity-icon avatar-xs">
                                                        <span
                                                            class="avatar-title bg-soft-primary text-primary rounded-circle">
                                                            {{ data.nama_user.charAt(0) }}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <h5 class="font-size-13">
                                                                {{ format_date(data.createdAt) }}
                                                                <small class="text-muted">{{ format_datetime(data.createdAt)
                                                                }}</small>
                                                            </h5>
                                                        </div>

                                                        <div>
                                                            <p class="text-muted mb-0">{{ data.text }}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul class="list-unstyled activity-wid" v-else>
                                                <li class="activity-list">
                                                    Tidak ada aktifitas
                                                </li>
                                            </ul>
                                        </div>
                                    </b-dropdown-text>
                                </b-dropdown>
                            </li>

                            <li class="list-inline-item" v-for="(data, i) in this.dataAnggota.slice(0, 5)" :key="i">
                                <a href="javascript: void(0);" class="d-inline-block" v-b-tooltip.hover
                                    :title="data.nama_user">
                                    <div class="avatar-xs">
                                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                                            data.nama_user.charAt(0) }}</span>
                                    </div>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a v-if="dataAnggota.length > 5" href="javascript: void(0);"
                                    class="team-member d-inline-block" v-b-tooltip.hover data-placement="top"
                                    :title="dataAnggota.length - 5 + ' other members'">
                                    <div class="avatar-xs">
                                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                                            ('...') }}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h4 class="card-title">Data Table</h4> -->
                            <div class="row mt-4 mb-4">
                                <div class="col-sm-6 col-md-6">
                                    <div class="text-md-left">

                                        <b-row>
                                            <b-col cols="3">
                                                <b-button v-b-modal.modal-no-backdrop-pdf variant="danger"><i
                                                        class="mdi mdi-file-pdf" variant="danger"></i> Export PDF</b-button>
                                            </b-col>
                                            <b-col cols="3">
                                                <b-button v-b-modal.modal-no-backdrop-excel variant="success"><i
                                                        class="mdi mdi-microsoft-excel" variant="danger"></i> Export
                                                    Excel</b-button>
                                            </b-col>

                                        </b-row>

                                    </div>
                                </div>

                                <!-- Search -->
                                <div class="col-sm-6 col-md-6">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-bordered mb-0">
                                <b-table :items="dataTask" :fields="fields" responsive="sm" :per-page="perPage"
                                    :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                                    head-variant="light">

                                    <template #cell(task)="data">
                                        <div v-if="data.item.level == 0">
                                            <b>{{ data.item.kode_task }} {{ data.item.task }}</b>
                                        </div>
                                        <div v-else :style="{ 'margin-left': data.item.level + '0px' }">
                                            <span>
                                                {{ data.item.kode_task }} {{ data.item.task }} </span>
                                        </div>
                                    </template>
                                    <template #cell(timeline)="data">
                                        <!-- <span v-if="data.item.type == 'parent'">
                                    <b>{{new Date(data.item.start).toLocaleDateString('id-ID', options)}} sd {{new Date(data.item.end).toLocaleDateString('id-ID', options)}}</b>
                                </span> -->
                                        <span>
                                            {{ format_date(data.item.start) + ' - ' + format_date(data.item.end) }}
                                        </span>
                                        <b-progress :value="data.item.progress" :max="100" show-value height="1rem"
                                            variant="success"></b-progress>
                                        <!-- <b-progress v-if="data.item.level != 0" :value="hitungSelisihHari(data.item.start,data.item.end)" :max="100" height="1rem" variant="warning" :label="'tes'"></b-progress> -->
                                    </template>
                                    <template #cell(anggota)="datas">

                                        <div v-if="datas.item.id_penanggungjawab != 0">
                                            <div class="team-member">
                                                <a v-for="(data, i) in datas.item.nama_penanggungjawab.slice(0, 2)" :key="i"
                                                    href="javascript: void(0);" class="team-member d-inline-block"
                                                    v-b-tooltip.hover data-placement="top" :title="data">
                                                    <div class="avatar-xs">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                                                                data.charAt(0) }}</span>
                                                    </div>
                                                </a>
                                                <a v-if="datas.item.nama_penanggungjawab.length > 2"
                                                    href="javascript: void(0);" class="team-member d-inline-block"
                                                    v-b-tooltip.hover data-placement="top"
                                                    :title="datas.item.nama_penanggungjawab.length - 2 + ' other members'">
                                                    <div class="avatar-xs">
                                                        <span
                                                            class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                                                                ('...') }}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                    </template>
                                    <!-- <template #cell(timeline)="data">
                                    <div v-if="data.item.level == 0"></div>
                                    <div v-else class="progress position-relative" style="height: 2rem;background-color: #000;">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 100%"
                                            aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                        <span class="justify-content-center w-100 mt-3 text-white"><strong>{{ data.item.timeline
                                        }}</strong></span>
                                    </div>
                                </template> -->


                                    <template #cell(action)="data">
                                        <div v-if="data.item.id_parent == 0"></div>
                                        <div v-else v-for="(datanya, index) in data.item.id_penanggungjawab" :key="index">

                                            <div v-if="datanya == dataProyek.id">
                                                <a class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                                                    title="Report " @click="getDataTask(data.item)">
                                                    <i class="mdi mdi-file font-size-24"></i>
                                                </a>
                                                <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                                                    title="Detail" @click="getDetailTask(data.item)">
                                                    <i class="mdi mdi-open-in-new font-size-24"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- modal tambah -->
            <b-modal body-class="bg-white" id="modal-report" scrollable title="Task Report" cancel-title="Close"
                ok-title="Accept" cancel-variant="outline-secondary" size="lg">
                <form ref="form" @submit.stop.prevent="onSubmit">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Task" label-for="name" class="mb-3">
                                <b-form-input v-validate="'required'" name="Task"
                                    :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                    v-model="report.task" disabled />
                                <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                    {{ errors.first('Nama Kegiatan') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Date" label-for="email" class="mb-3">
                                <date-picker v-model="report.date" date append-to-body lang="id"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="dataProyek.time_report">
                            <b-form-group label="Start Date" label-for="email" class="mb-3">
                                <date-picker v-model="report.jam_mulai" format="hh:mm a" value-type="format" type="time"
                                    placeholder="hh:mm a"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="dataProyek.time_report">
                            <b-form-group label="End Date" label-for="email" class="mb-3">
                                <date-picker v-model="report.jam_selesai" format="hh:mm a" value-type="format" type="time"
                                    placeholder="hh:mm a"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Progress Task" label-for="progress" class="mb-3">
                                <b-input-group size="m" append="%">
                                    <b-form-input v-validate="'required'" type="number" name="durasi"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('progress') }"
                                        id="progress" v-model="report.progres" step="0.1" />
                                    <span class="text-danger" v-if="errors.has('progress')">
                                        {{ errors.first('progress') }}
                                    </span>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Detail Report" label-for="email" class="mb-3">
                                <b-form-textarea id="textarea-default" placeholder="Input detail report"
                                    v-model="report.catatan"></b-form-textarea>
                                <span class="text-danger" v-if="errors.has('durasi')">
                                    {{ errors.first('durasi') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>


                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" style="float:right" @click="onSubmit">
                            <i class="fas fa-save" />

                            Save
                        </b-button>
                    </div>
                </template>
            </b-modal>

            <!-- modal edit -->
            <b-modal body-class="bg-white" id="modal-report-edit" scrollable title="Task Report" cancel-title="Close"
                ok-title="Accept" cancel-variant="outline-secondary" size="lg">
                <form ref="form" @submit.stop.prevent="onSubmit">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Task" label-for="name" class="mb-3">
                                <b-form-input v-validate="'required'" name="Task"
                                    :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                    v-model="reportEdit.nama_task" disabled />
                                <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                    {{ errors.first('Nama Kegiatan') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Date" label-for="date" class="mb-3">
                                <date-picker v-model="reportEdit.tanggal" date append-to-body lang="id"
                                    value-type="format"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="dataProyek.time_report">
                            <b-form-group label="Start Date" label-for="email" class="mb-3">
                                <date-picker v-model="reportEdit.jam_mulai" format="hh:mm a" value-type="format" type="time"
                                    placeholder="hh:mm a"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="dataProyek.time_report">
                            <b-form-group label="End Date" label-for="email" class="mb-3">
                                <date-picker v-model="reportEdit.jam_selesai" format="hh:mm a" value-type="format"
                                    type="time" placeholder="hh:mm a"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Progress Task" label-for="progress" class="mb-3">
                                <b-input-group size="m" append="%">
                                    <b-form-input v-validate="'required'" type="number" name="durasi"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('progress') }"
                                        id="progress" v-model="reportEdit.progres" step="0.1" />
                                    <span class="text-danger" v-if="errors.has('progress')">
                                        {{ errors.first('progress') }}
                                    </span>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Detail Report" label-for="email" class="mb-3">
                                <b-form-textarea id="textarea-default" placeholder="Input detail report"
                                    v-model="reportEdit.catatan"></b-form-textarea>
                                <span class="text-danger" v-if="errors.has('durasi')">
                                    {{ errors.first('durasi') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>


                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" style="float:right" @click="EditReport">
                            <i class="fas fa-save" />

                            Save
                        </b-button>
                    </div>
                </template>
            </b-modal>
            <!-- modal tambah report-->
            <b-modal body-class="bg-white" id="modal-tambah-report" scrollable title="Task Report" cancel-title="Close"
                ok-title="Accept" cancel-variant="outline-secondary" size="lg">
                <form ref="form" @submit.stop.prevent="onSubmitReport">
                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Task" label-for="name" class="mb-3">
                                <b-form-input v-validate="'required'" name="Task"
                                    :class="{ 'form-control': true, 'is-invalid': errors.has('Nama Kegiatan') }" id="name"
                                    v-model="report.task" disabled />
                                <span class="text-danger" v-if="errors.has('Nama Kegiatan')">
                                    {{ errors.first('Nama Kegiatan') }}
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Date" label-for="email" class="mb-3">
                                <date-picker v-model="report.date" date append-to-body lang="id"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="dataProyek.time_report">
                            <b-form-group label="Start Date" label-for="email" class="mb-3">
                                <date-picker v-model="report.jam_mulai" format="hh:mm a" value-type="format" type="time"
                                    placeholder="hh:mm a"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="dataProyek.time_report">
                            <b-form-group label="End Date" label-for="email" class="mb-3">
                                <date-picker v-model="report.jam_selesai" format="hh:mm a" value-type="format" type="time"
                                    placeholder="hh:mm a"></date-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Progress Task" label-for="progress" class="mb-3">
                                <b-input-group size="m" append="%">
                                    <b-form-input v-validate="'required'" type="number" name="durasi"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('progress') }"
                                        id="progress" v-model="report.progres" step="0.1" />
                                    <span class="text-danger" v-if="errors.has('progress')">
                                        {{ errors.first('progress') }}
                                    </span>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Detail Report" label-for="email" class="mb-3">
                                <b-form-textarea id="textarea-default" placeholder="Input detail report"
                                    v-model="report.catatan"></b-form-textarea>
                                <span class="text-danger" v-if="errors.has('durasi')">
                                    {{ errors.first('durasi') }}
                                </span>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>


                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" style="float:right" @click="onSubmitReport">
                            <i class="fas fa-save" />

                            Save
                        </b-button>
                    </div>
                </template>
            </b-modal>


            <!-- modal tambah -->
            <b-modal body-class="bg-white" id="modal-detail" scrollable title="Detail Task" cancel-title="Close"
                ok-title="Accept" cancel-variant="outline-secondary" size="lg">
                <form ref="form" @submit.stop.prevent="tambahdata">
                    <b-row>
                        <b-col md="12">
                            <div class="card">
                                <div class="card-body border-bottom">
                                    <div id="todo-task" class="task-list">
                                        <div class="card task-box">
                                            <div class="progress progress-sm animated-progess" style="height: 3px">
                                                <div class="progress-bar" role="progressbar"
                                                    :style="{ width: this.report.progress + '%' }"
                                                    :aria-valuenow="this.report.progress" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                            <div class="card-body">
                                                <div class="float-right ml-2">
                                                    <div>{{ formattedDate(this.report.createdAt) }}</div>
                                                </div>
                                                <div class="mb-3">
                                                    <a href="#" class>{{ this.report.kode_task }}</a>
                                                </div>
                                                <div>
                                                    <h5 class="font-size-16">
                                                        <a href="javascript: void(0);" class="text-dark">{{ this.report.task
                                                        }}</a>
                                                    </h5>
                                                    <p class="mb-1"></p>
                                                </div>
                                                <div>
                                                    <h5 class="font-size-14"
                                                        v-if="this.report.deskripsi_task == 'undefined' || this.report.deskripsi_task == null">

                                                    </h5>
                                                    <h5 class="font-size-14" v-else>
                                                        {{ this.report.deskripsi_task }}
                                                    </h5>
                                                    <p class="mb-4"></p>
                                                </div>

                                                <div class="d-inline-flex team mb-3">
                                                    <div class="mr-3 align-self-center">Member : </div>
                                                    <div class="team-member"
                                                        v-for="(data, i) in this.report.nama_penanggungjawab.slice(0, 10)"
                                                        :key="i">
                                                        <a href="javascript: void(0);" class="team-member d-inline-block"
                                                            v-b-tooltip.hover data-placement="top" :title="data">
                                                            <div class="avatar-xs">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                                                                        data.charAt(0) }}</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="team-member"
                                                        v-if="this.report.nama_penanggungjawab.length > 10">
                                                        <a href="javascript: void(0);" class="team-member d-inline-block"
                                                            v-b-tooltip.hover data-placement="top"
                                                            :title="this.report.nama_penanggungjawab.length - 10 + ' other members'">
                                                            <div class="avatar-xs">
                                                                <span
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                                                                        ('...') }}</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="d-inline-flex team mb-0">
                                                    <div class="mr-3 align-self-center">Timeline : {{
                                                        this.format_date(this.report.start) + " to " +
                                                        this.format_date(this.report.end) }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end task card -->

                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <b-button variant="success" size="sm" class="mb-2"
                                                v-b-modal.modal-tambah-report><i class="fas fa-plus"></i> Report</b-button>
                                        </div>
                                        <!-- <div class="col-sm-6 col-md-6">
                                                                                    <div id="tickets-table_length" class="dataTables_length">
                                                                                    <label class="d-inline-flex align-items-center">
                                                                                        Show&nbsp;
                                                                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                                                                    </label>
                                                                                    </div>
                                                                                </div> -->
                                        <!-- Search -->
                                        <div class="col-sm-6 col-md-6">
                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                <label class="d-inline-flex align-items-center">
                                                    Search:
                                                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                        class="form-control form-control-sm ml-2"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <!-- Table -->
                                    <div class="table-responsive mb-0">
                                        <b-table :items="dataReport" :fields="fieldsReport" responsive="sm"
                                            :per-page="perPageReport" :current-page="currentPageReport"
                                            :sort-by.sync="sortByReport" :sort-desc.sync="sortDescReport"
                                            :filter="filterReport" :filter-included-fields="filterOnReport"
                                            @filtered="onFilteredReport">
                                            <template #cell(tanggal)="data">
                                                <span> {{ data.item.tanggal }}</span>
                                            </template>
                                            <template #cell(action)="data">
                                                <a class="mr-3 text-primary" v-b-modal.modal-edit
                                                    @click="getDetail(data.item)"><i
                                                        class="fas fa-edit font-size-16"></i></a>
                                                <a class="text-danger" @click="deleteData(data.item.id)"
                                                    style="cursor: pointer;"><i
                                                        class="far fa-trash-alt font-size-16"></i></a>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Show&nbsp;
                                                    <b-form-select v-model="perPageReport" size="sm"
                                                        :options="pageOptionsReport"></b-form-select>&nbsp;entries
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPageReport" :total-rows="rowsReport"
                                                        :per-page="perPageReport"></b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </form>


                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="danger" style="float:right" @click="$bvModal.hide('modal-detail')">
                            <i class="fa fa-floppy-o" />

                            Close
                        </b-button>
                    </div>
                </template>
            </b-modal>

            <b-modal id="modal-no-backdrop-pdf" content-class="shadow" title="Export Data Report PDF" centered
                @ok="exportPDF()" ok-title="Export">
                <b-row>
                    <b-col md="5">
                        <b-form-group label-for="email" class="mb-3">
                            <date-picker v-model="exportData.datestart" date append-to-body lang="en"></date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" class="text-center">
                        <span>to</span>
                    </b-col>
                    <b-col md="5">
                        <b-form-group label-for="email" class="mb-3">
                            <date-picker v-model="exportData.dateend" date append-to-body lang="en"></date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-checkbox id="checkbox-1" v-model="cetakbawahan" name="checkbox-1" value="accepted"
                            unchecked-value="not_accepted">
                            Cetak Laporan Bawahan
                        </b-form-checkbox>
                    </b-col>
                </b-row>

            </b-modal>

            <b-modal id="modal-no-backdrop-excel" content-class="shadow" title="Export Data Report Excel" centered
                @ok="exportExcel()" ok-title="Export">
                <b-row>
                    <b-col md="5">
                        <b-form-group label-for="email" class="mb-3">
                            <date-picker v-model="exportData.datestart" date append-to-body lang="en"></date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="2" class="text-center">
                        <span>to</span>
                    </b-col>
                    <b-col md="5">
                        <b-form-group label-for="email" class="mb-3">
                            <date-picker v-model="exportData.dateend" date append-to-body lang="en"></date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-checkbox id="checkbox-1" v-model="cetakbawahanExcell" name="checkbox-1" value="accepted"
                            unchecked-value="not_accepted">
                            Cetak Laporan Bawahan
                        </b-form-checkbox>
                    </b-col>

                </b-row>

            </b-modal>

        </Layout>
    </div>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios'
import Swal from "sweetalert2";
//import vSelect from 'vue-select'
import DatePicker from "vue2-datepicker";
import moment from 'moment'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */
export default {
    page: {
        title: "Report",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, DatePicker, Loading },
    data() {
        return {
            //   tableData: tableData,
            baseapi: localStorage.getItem("baseapi"),
            idProyek: localStorage.getItem("idProyek"),
            user: JSON.parse(localStorage.getItem('user')),
            dataProyek: JSON.parse(localStorage.getItem('proyek')),
            title: "Report",
            dataAnggota: [],
            isLoading: false,
            items: [
                {
                    text: "Project",
                    href: "/"
                },
                {
                    text: "Report",
                    active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "task", label: 'Tasks', sortable: true },
                { key: "anggota", label: 'Member', sortable: true },
                { key: "timeline", label: 'Timeline/Task Progress', sortable: true },
                'action'
            ],
            currentPageReport: 1,
            perPageReport: 10,
            pageOptionsReport: [10, 25, 50, 100],
            filterReport: null,
            filterOnReport: [],
            sortByReport: "age",
            sortDescReport: false,
            cetakbawahan: 'not_accepted',
            cetakbawahanExcell: 'not_accepted',
            fieldsReport: [
                { key: "catatan", label: 'Detail Report', sortable: true },
                { key: "progres", label: 'Personal Progress', sortable: true },
                { key: "tanggal", label: 'Date', sortable: true },
                'action'

            ],
            // dataTask: [
            //     {
            //         task: 'Task 1',
            //         timeline: '12 Jan - 30 Des',
            //         progress: 31,
            //     }
            // ],
            dataTask: [],
            report: {
                id_task: null,
                task: null,
                id_member: null,
                date: null,
                jam_mulai: null,
                progres: null,
                catatan: null,
                kode_task: null,
                nama_penanggungjawab: []
            },
            reportEdit: {},
            selected: null,
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Selected Option' },
                { value: { C: '3PO' }, text: 'This is an option with object value' },
                { value: 'd', text: 'This one is disabled', disabled: true }
            ],
            daterange: "",
            dataReport: [],
            activityData: [],
            exportData: {
                datestart: null,
                dateend: null
            },
            showJam: true

        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.dataTask.length;
        },
        rowsReport() {
            return this.dataReport.length;
        }
    },
    mounted() {
        this.loaddata()
        this.loadAnggota()
        // Set the initial number of items
        this.totalRows = this.dataTask.length;
        this.totalRowsReport = this.dataReport.length;
    },
    methods: {
        updateValue(event) {
            const value = event.target.value
            //console.log(value, this.amount)
            if (String(value).length <= 10) {
                this.report.progres = value
            }
            this.$forceUpdate()
        },
        getNowDay(value) {
            var hari;
            if (value) {
                hari = moment(String(value)).format('dddd')
                if (hari == 'Sunday') {
                    return value = '0'
                } else if (hari == 'Monday') {
                    return value = '1'
                } else if (hari == 'Tuesday') {
                    return value = '2'
                } else if (hari == 'Wednesday') {
                    return value = '3'
                } else if (hari == 'Thursday') {
                    return value = '4'
                } else if (hari == 'Friday') {
                    return value = '5'
                } else if (hari == 'Saturday') {
                    return value = '6'
                }
            }
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format('hh.mm ')
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('ddd, DD MMMM YYYY ')
            }
        },
        formattedDate(tanggal) {
            return moment(tanggal).format('DD, MMMM yyyy');
        },
        formatDateExport(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        hitungSelisihHari(tgl1, tgl2) {
            // varibel miliday sebagai pembagi untuk menghasilkan hari
            var miliday = 24 * 60 * 60 * 1000;
            //buat object Date
            var tanggal1 = new Date(tgl1);
            var tanggal2 = new Date(tgl2);
            // Date.parse akan menghasilkan nilai bernilai integer dalam bentuk milisecond
            var tglPertama = Date.parse(tanggal1);
            var tglKedua = Date.parse(tanggal2);
            var selisih = (tglKedua - tglPertama) / miliday;

            if (selisih < 1) {
                return 0
            } else {
                return selisih
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFilteredReport(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsReport = filteredItems.length;
            this.currentPageReport = 1;
        },

        getDataTask(data) {
            this.report.task = data.task;
            this.report.id_task = data.id;
            this.report.id_member = this.dataProyek.id

            this.$nextTick(() => {
                this.$bvModal.show('modal-report')
            });
            console.log(this.report.task)
        },

        clearForm() {
            this.report.catatan = null
            this.report.progres = null

        },

        getDetailTask(data) {
            console.log(data)
            this.report = data

            this.report.id_task = data.id;

            this.clearForm()

            axios.get(this.baseapi + 'api/daily_report?filter=id_task,=,' + data.id + ';id_member,=,' + this.dataProyek.id,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }
            ).then(res => {
                console.log(res.data)
                this.dataReport = res.data.data
            })
            this.$nextTick(() => {
                this.$bvModal.show('modal-detail')
            });
        },

        deleteData(id) {
            console.log(id)

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(
                            this.baseapi + `api/daily_report/` + id,
                            {
                                headers: {
                                    Authorization: `Bearer ` + this.user.accessToken
                                }
                            }
                        )
                        .then((res) => {

                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil Dihapus',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.dataTask = []
                            this.refreshDetail()
                            this.loaddata()
                            return res;
                        })
                        .catch((err) => {
                            console.log(err)
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            return err;
                        });
                }
            })

        },
        refreshDetail() {
            axios.get(this.baseapi + 'api/daily_report?filter=id_task,=,' + this.report.id_task + ';id_member,=,' + this.dataProyek.id,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }
            ).then(res => {
                console.log(res.data)
                this.dataReport = res.data.data
            })
        },

        onSubmitReport() {
            if (this.report.progres > 100) {
                Swal.fire({
                    icon: 'error',
                    title: "Progress must be below 100%",
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                this.isLoading = true;
                this.report.id_member = this.dataProyek.id
                console.log(this.report.id_task)
                this.$validator.validateAll();
                const tanggal_report = this.formatDateExport(this.report.date)
                axios.post(this.baseapi + 'api/daily_report', {
                    id_member: this.report.id_member,
                    id_task: this.report.id_task,
                    progres: this.report.progres,
                    catatan: this.report.catatan,
                    tanggal: tanggal_report,
                    jam_mulai: this.report.jam_mulai,
                    jam_selesai: this.report.jam_selesai
                }, {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                })
                    .then((res) => {

                        this.isLoading = false;
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil Ditambah',
                            showConfirmButton: false,
                            timer: 1500
                        })

                        this.clearForm()

                        axios.get(this.baseapi + 'api/daily_report?filter=id_task,=,' + this.report.id_task + ';id_member,=,' + this.dataProyek.id,
                            {
                                headers: {
                                    Authorization: `Bearer ` + this.user.accessToken
                                }
                            }
                        ).then(res => {
                            this.isLoading = false;
                            console.log(res.data)
                            this.dataReport = res.data.data
                        })

                        // Wait until the models are updated in the UI
                        this.$nextTick(() => {
                            this.updateData(this.report.id_task)
                            this.$bvModal.hide('modal-tambah-report')
                        });
                        return res;
                    })
                    .catch((err) => {
                        console.log(err)
                        this.isLoading = false;
                        Swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        return err;
                    });
            }

        },


        onSubmit() {
            if (this.report.progres > 100) {
                Swal.fire({
                    icon: 'error',
                    title: "Progress must be below 100%",
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                this.isLoading = true;
                this.report.id_member = this.dataProyek.id
                this.$validator.validateAll();

                var today = new Date()
                console.log(this.getNowDay(today))

                var arraykerja = this.dataProyek.hari_kerja.split("||");

                const tanggal_report = this.formatDateExport(this.report.date)

                if (arraykerja.includes(this.getNowDay(today)) == true) {
                    axios.post(this.baseapi + 'api/daily_report', {
                        id_member: this.report.id_member,
                        id_task: this.report.id_task,
                        progres: this.report.progres,
                        catatan: this.report.catatan,
                        tanggal: tanggal_report,
                        jam_mulai: this.report.jam_mulai,
                        jam_selesai: this.report.jam_selesai
                    }, {
                        headers: {
                            "Content-type": "application/json",
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    })
                        .then((res) => {
                            this.isLoading = false;
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil Ditambah',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.dataTask = []
                            this.clearForm()
                            this.report.date = null
                            // this.loaddata()


                            // Wait until the models are updated in the UI
                            this.$nextTick(() => {
                                this.updateData(this.report.id_task)
                                this.$bvModal.hide('modal-report')
                            });
                            return res;
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            console.log(err)
                            Swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            return err;
                        });
                } else {
                    this.isLoading = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Make sure you fill in according to working days',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }

            }

        },

        EditReport() {
            if (this.reportEdit.progres > 100) {
                Swal.fire({
                    icon: 'error',
                    title: "Progress must be below 100%",
                    showConfirmButton: false,
                    timer: 1500
                })
            } else {
                this.isLoading = true;
                this.$validator.validateAll();
                const tanggal_report = this.formatDateExport(this.reportEdit.tanggal)
                axios
                    .put(
                        this.baseapi + `api/daily_report/${this.reportEdit.id}`,
                        {
                            id_member: this.reportEdit.id_member,
                            id_task: this.reportEdit.id_task,
                            progres: this.reportEdit.progres,
                            catatan: this.reportEdit.catatan,
                            tanggal: tanggal_report,
                        }
                        , {
                            headers: {
                                Authorization: `Bearer ` + this.user.accessToken
                            }
                        })
                    .then((res) => {
                        this.isLoading = false;
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil Diubah',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.refreshDetail()
                        this.$nextTick(() => {
                            this.updateData(this.reportEdit.id_task)
                            this.$bvModal.hide('modal-report-edit')
                        })
                        return res;
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        return err;
                    });
            }
        },

        // loaddata user
        loaddata() {
            this.dataTask = []
            axios.get(this.baseapi + "api/task?filter=id_proyek,=," + this.idProyek + '&sort=kode_task,ASC', {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                console.log(response.data)
                response.data.data.forEach(element => {
                    this.dataTask.push({
                        level: element.level,
                        id: element.id,
                        task: element.nama_task,
                        timeline: element.start + ' s.d. ' + element.end,
                        start: element.start,
                        end: element.end,
                        progress: element.progres,
                        id_penanggungjawab: element.id_penanggungjawab,
                        nama_penanggungjawab: element.nama_penanggungjawab,
                        kode_task: element.kode_task,
                        createdAt: element.createdAt,
                        deskripsi_task: element.deskripsi_task,
                    })
                });
            }).catch(error => {
                console.log(error)
                return error
            });

            axios.get(this.baseapi + 'api/daily_report?filter=id_proyek,=,' + this.idProyek,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }).then(response => {
                    this.activityData = response.data.data
                }).catch(error => {
                    return error
                })
        },

        updateData(id) {


            axios.get(this.baseapi + "api/task?filter=id,=," + id, {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                var dataResponse = []
                dataResponse = response.data.data
                //Find index of specific object using findIndex method.    
                var objIndex = this.dataTask.findIndex(obj => obj.id === id);

                //Update object's name property.
                this.dataTask[objIndex].progress = dataResponse[0].progres

            }).catch(error => {
                console.log(error)
                return error
            });


        },
        // detail
        getDetail(data) {
            console.log(data)
            this.reportEdit = data
            this.$nextTick(() => {
                this.$bvModal.show('modal-report-edit')
            });
            // axios.get(this.baseapi + "api/daily_report/" + id, {
            //     headers: {
            //         Authorization: `Bearer ` + this.user.accessToken
            //     }
            // }).then(response => {
            //     console.log(response.data.data)
            //     this.report = response.data.data;
            //     this.$nextTick(() => {
            //         this.$bvModal.show('modal-report')
            //     });
            // }).catch(error => {
            //     console.log(error)
            //     return error
            // });
        },

        loadAnggota() {
            axios.get(this.baseapi + "api/assign_member?filter=id_proyek,=," + this.idProyek, {
                headers: {
                    Authorization: `Bearer ` + this.user.accessToken
                }
            }).then(response => {
                this.dataAnggota = response.data.data;
                // if (data === 'anggota') {
                //     this.$bvModal.show('modal-anggota')
                // } else {
                //     this.detailTask = []
                //     this.$bvModal.show('modal-tambah')
                // }
            }).catch(error => {
                console.log(error)
                return error
            });
        },


        exportExcel() {
            this.isLoading = true;
            const today = new Date();
            const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate();
            const time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
            const dateTime = date + '-' + time;

            const dateStart = this.formatDateExport(this.exportData.datestart)
            const dateEnd = this.formatDateExport(this.exportData.dateend)

            var baseurl = ''
            if (this.cetakbawahanExcell == 'not_accepted') {
                baseurl = this.baseapi + 'api/daily_report/getExcel?filter=id_proyek,=,' + this.idProyek + ';id_member,=,' + this.dataProyek.id + ';tanggal,>=,' + dateStart + ';tanggal,<=,' + dateEnd
            } else if (this.cetakbawahanExcell == 'accepted') {
                baseurl = this.baseapi + 'api/daily_report/getExcel?filter=id_proyek,=,' + this.idProyek + ';tanggal,>=,' + dateStart + ';tanggal,<=,' + dateEnd + '&include=bawahan'
            }
            axios.get(baseurl, {
                headers: {
                    'Content-Disposition': 'attachment; filename=export-data.xlsx',
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                responseType: 'arraybuffer',
            },
            )
                .then((response) => {

                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'export-excel-' + dateTime
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.response.status)
                    this.isLoading = false;
                    if (error.response.status == 400) {
                        Swal.fire({
                            icon: 'error',
                            title: "Unable to find report!",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: error.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }

                    return error;
                })// Please catch me!

            // this.exportexcell = this.baseapidokumen+'getExcel'
        },

        exportPDF() {
            this.isLoading = true;
            const today = new Date();
            const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate();
            const time = today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
            const dateTime = date + '-' + time;

            const dateStart = this.formatDateExport(this.exportData.datestart)
            const dateEnd = this.formatDateExport(this.exportData.dateend)

            var baseurl = ''
            if (this.cetakbawahan == 'not_accepted') {
                baseurl = 'https://dokumen.mroyek.com/getDailyReport?filter=id_proyek,=,' + this.idProyek + ';id_member,=,' + this.dataProyek.id + ';tanggal,>=,' + dateStart + ';tanggal,<=,' + dateEnd
            } else if (this.cetakbawahan == 'accepted') {
                baseurl = 'https://dokumen.mroyek.com/getDailyReport?filter=id_proyek,=,' + this.idProyek + ';id_member,=,' + this.dataProyek.id + ';tanggal,>=,' + dateStart + ';tanggal,<=,' + dateEnd + '&include=bawahan'
            }

            axios.get(baseurl, {
                headers: {
                    'Content-Disposition': 'attachment; filename=export-data.pdf',
                    'Content-Type': 'application/pdf',
                },
                responseType: 'arraybuffer',
            },
            )
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: 'application/pdf',
                    })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'export-pdf-' + dateTime
                    link.click()
                    URL.revokeObjectURL(link.href)
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.response.status)
                    this.isLoading = false;
                    if (error.response.status == 400) {
                        Swal.fire({
                            icon: 'error',
                            title: "Unable to find report!",
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: error.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }

                    return error;
                })// Please catch me!

            // this.exportexcell = this.baseapidokumen+'getExcel'
        },


    }
};
</script>